/* Contact.css */
.icon {
    color: white;
    margin: 0 10px;
    transition: transform 0.3s ease; /* Animáció hozzáadása */
    padding-bottom: 20px;

  }


  #linktree{
    width: 40px;
  }
  
  .icon:hover {
    transform: scale(1.2); /* Egér fölé helyezésre növeli a méretet */
  }
  
  /* footer.css */
.footer {
  font-weight: bold;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 13px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.text {
  margin: 0;
  font-size: 10px;
}

/* contact.css */
.contact-container {
  background-color: #ec6a1ff1;
  padding: 20px;
  position:relative;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}




.telnumber {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
}
#tel:hover + .telnumber {
  display: block;
}
