/* Példa stílusok, testre szabhatod őket az igényeid szerint */
.popup-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Átlátszó háttér */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
  }
  
  .popup {
    background: #fff;
    padding: 20px;

    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .popup-content {
    position: relative; /* Adjuk hozzá a position: relative-t a .popup-content-hez */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close {
    cursor: pointer;
    font-size: 18px;
    color: #333;

    padding: 4px;
    position: absolute; /* Add hozzá a position: absolute-t */
    top: 10px;
    right: 10px;
  }
  
  #closebutton {
    width: 41px;
    height: 41px;
  }

  .product-details {
    flex: 1;
    
    text-align: left; /* A szöveg balra igazítása */
 
  }
  
  
  .product-details h2 {
    margin-bottom: 10px;
    margin-top: 0; /* Ne legyen felső margó a termék nevének */
  }
  
  #productimg{
        width: 51%;
  }
  
li{
    border: none;
    margin-top: 2px;
}
#title{
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  
}
