/* slider.css */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  
  .navbar {
    background-color: #ec6a1ff1;
    padding: 10px;

    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .navbar-links a {
    color: white;
    margin-right: 15px;
    text-decoration: none;
  }
  
  .slider-container {
    width: 100%;
    height: calc(100vh - 60px);
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0; 
  }
  

  
  .welcome-text {
    color: #ec6a1ff1;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-shadow: -3px   -3px white;
    font-size: 44px;
    margin-bottom: 20px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

  }

  @media only screen and (max-width: 600px) {
    .welcome-text {
        font-size: 32px; /* A kívánt kisebb betűméret */
    }
}
  
  .full-screen-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  
  .left {
    left: 10px;
  }
  
  .right {
    right: 10px;
  }
  
  .arrow:before {
    content: '';
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #333;
    border-left: 0;
  }
  
  .right:before {
    border: 8px solid transparent;
    border-left-color: #333;
    border-right: 0;
  }
  
  #logo{
    width: 40px;
    height: 40px;

    
  }

  .flag{
    width: 23px;
    height: 18px;
  }
  .nav-link{
    color: white;
  }

  .nav-link:hover{
    color: rgba(255, 255, 255, 0.63);
  }
  

  /* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ec6a1ff1;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #e96b23c7;
}

#navbar-address{
  float: right;
}


/* Default positioning */
#fonetti_logo {
  position: absolute;
  padding-top: 120px;
  max-width: 300px;

  

}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  #fonetti_logo {
    width: 100%; /* Ensure the logo fills the width of the screen */
    max-width: 200px; /* Set maximum width to maintain proportion */
    padding-top: 120px; /* Adjust padding as needed */
  }
}


