.login-container {
    position: absolute; /* Abszolút pozíció */
    top: 50%; /* 50%-ra helyezzük a tetejét a szülőelem tetejétől */
    left: 50%; /* 50%-ra helyezzük a bal oldalát a szülőelem bal oldalától */
    transform: translate(-50%, -50%); /* Középre igazítás elmozdulás segítségével */
    width: auto; /* vagy bármilyen más szélesség, amelyet szeretnél */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    text-align: center; /* középre igazítjuk a tartalmat */
  }
  
  .login-container h1 {
    margin-bottom: 20px; /* növeljük a távolságot a bejelentkezési űrlap fejléce és a form között */
  }
  
  .login-container form {
    display: inline-block; /* az űrlapok középre igazításához */
    text-align: left; /* az űrlap tartalmának balra igazítása */
  }
  
  .login-container input {
    width: 100%; /* az input mezők teljes szélessége */
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  
  .login-container button {
    width: 100%; /* a gomb teljes szélessége */
    padding: 10px;
    background-color: #ec6a1ff1;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #ec6a1f9f;
  }
  