.product-container {
    max-width: 800px;
    margin: auto;
    opacity: 0;
   
    transform: translateY(20px);
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
  }
  
  .product-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  
  .product-list li {
    width: calc(33.333% - 20px); /* Kiszámoljuk a szélességet a térközökkel együtt */
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    transition: transform 0.2s ease-in-out;
  }
  
  .product-image {
    padding-top: 20px;
    width: auto;
    height: 60%;
    margin: auto;
    max-width: 80%;
    transition: transform 0.2s ease-in-out;
  }
  
  .product-card{
    height: 290px;


    --c: #ec6a1ff1; /* the border color */
  --b: 4px;    /* the border thickness*/
  --g: 3px; 
    padding: calc(var(--g) + var(--b));
    --_g: #0000 25%,var(--c) 0;
    background: 
      conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g))
       var(--_i,200%) 0  /200% var(--_i,var(--b))  no-repeat,
      conic-gradient(            at bottom var(--b) left  var(--b),var(--_g))
       0   var(--_i,200%)/var(--_i,var(--b)) 200%  no-repeat;
    transition: .3s, background-position .3s .3s;
    cursor: pointer;

  }

  .product-card:hover{
   



    
      --_i: 100%;
      transition: .3s, background-size .3s .3s;
    
  }

  #label{
    padding-bottom: 10px;
  }

  .product-card:hover {
    transform: scale(1.05);
  }
  
  @media (max-width: 600px) {
    .product-list li {
      width: calc(50% - 20px); /* Kisebb képernyőn két elem egy sorban */
    }
  }
  
  @media (max-width: 400px) {
    .product-list li {
      width: calc(100% - 20px); /* Kisebb képernyőn egy elem egy sorban */
    }
  }
  

  
  
  .product-container.visible {
    opacity: 1;
    transform: translateY(0);
  
  }
  
  #typeSelect {
    border-radius: 10px;
    text-align: center;
    background-color: #f0f0f0;
    border: white;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  #typeSelect:hover {
    background-color: #ec6a1f8a;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  #akcio_text{
    color: red;
    font-weight: bold;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    font-size:x-large;
    padding: 10px;
    position: absolute;
  }

  #price{
    
    
    font-size: x-large;
   
    font-family: 'Vrinda', cursive;
    /*font-family: "Roboto Condensed", sans-serif;*/
  }
  .card-title{
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  #termekeink{
    padding: 10px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }

  /* #price{
    font-weight:bold;
    
    font-size: xx-large;
    font-family: 'Bradley Hand ITC', cursive;
    
    
  }



    #price{
    
    
    font-size: x-large;
    font-family: 'Vrinda', cursive;
    /*font-family: "Roboto Condensed", sans-serif;
  }
    
    */
  
    
  